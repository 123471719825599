export const tools: string[] = [
    "JavaScript & TypeScript",
    "Node.js, Express, React, & Redux",
    "Semantic/Accessible HTML5",
    "CSS3 & pre-processors",
    "GraphQL, Apollo Server, & Apollo Client",
    "Jest, Jasmine, Enzyme, React Testing Library, etc.",
    "Dart and Flutter",
    "Git & Github",
    "Amazon Web Services",
    "Docker & Kubernetes",
    "Postgres & Sequelize",
    "Progressive web app development",
    "REST API development",
    "Bootstrap, Foundation, Semantic UI, etc.",
]