import React from "react";
import ExternalLink from "../common/ExternalLink";

export interface PortfolioItemProps {
    title: string;
    demoUrl: string;
    codeUrl?: string;
    description: React.ReactNode;
}

export default function PortfolioItem({
    title,
    demoUrl,
    codeUrl,
    description
}: PortfolioItemProps) {
    const demoLink = (
        <ExternalLink href={demoUrl}>external link</ExternalLink>
    );
    const codeLink = (
        codeUrl &&
            <>
                <span> / </span>
                <ExternalLink href={codeUrl}>code</ExternalLink>
            </>
    );

    return (
        <div>
            <h3>{title}</h3>
            {demoLink}
            {codeLink}
            {description}
        </div>
    )
}