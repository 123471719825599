import React from "react";
import ExternalLink from "../common/ExternalLink";
import InlineListItem from "../common/InlineListItem";

interface AddressItemProps {
    href: string;
    text: string;
    isLast: boolean;
}

export default function AddressItem({href, text, isLast}: AddressItemProps) {
    return (
        <InlineListItem isLast={isLast}>
            <ExternalLink href={href}>{text}</ExternalLink>
        </InlineListItem>
    )
}
