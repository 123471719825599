import React from "react";
import {portfolioItems} from "./portfolioItems";
import PortfolioItem from "./PortfolioItem";

export default function Portfolio() {
    return (
        <section>
            <h2 className="visually-hidden">Portfolio</h2>
            {portfolioItems.map((item) => <PortfolioItem {...item} key={item.title} />)}
        </section>
    )
}
