import React, {PropsWithChildren} from "react";

type InlineListItemProps = PropsWithChildren<{
    isLast: boolean;
}>;

export default function InlineListItem({children, isLast}: InlineListItemProps) {
    return (
        <li className="inline-list-item">
            {children}
            {!isLast && <span>&nbsp;|&nbsp;</span>}
        </li>
    )
}
