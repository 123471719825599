import React from "react";
import { routes } from "../common/routes";
import NavItem from "./NavItem";


export default function Nav() {
    const routeEntries = Object.entries(routes);

    return (
        <nav>
            <ul>
                {routeEntries.map(
                    ([name, path], index) => (
                        <NavItem
                            key={name}
                            name={name}
                            path={path}
                            isLast={index === routeEntries.length - 1}
                        />
                    ))
                }
            </ul>
        </nav>
    )
}
