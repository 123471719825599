import React from "react";
import ExternalLink from "../common/ExternalLink";
import { PortfolioItemProps } from "./PortfolioItem";


export const portfolioItems: PortfolioItemProps[] = [
    {
        title: "Google Fuchsia OS Smart Displays",
        demoUrl: "https://store.google.com/us/product/nest_hub_2nd_gen",
        description: (
          <p>
            The Google Nest Hub is the "center of the helpful home", allowing consumers to control thousands of compatible smart devices from one central display. The Nest Hub and Nest Hub Max are the first consumer devices to run Fuchsia OS. Using Dart and Flutter, I Developed and maintained UI features for these devices with millions of users. I also contributed to developer workflow tech debt reduction by streamlining integrations between device emulators and virtual smart home environments.
          </p>
        )
    },
    {
        title: "SAP Concur Travel Booking Tool",
        demoUrl: "https://www.concur.com/en-us/travel-booking",
        description: (
            <p>
                Concur is the industry-leading enterprise travel booking tool with millions of active users. For 3.5 years I was a core contributor building next-generation user interfaces with a fully-modern web stack: TypeScript, React, Redux, Node, and GraphQL. I Led the development of the UI for Strong Customer Authentication in accordance with the EU’s Revised Directive on Payment Services. I also developed and maintained an ecosystem of common UI runtime packages used by dozens of developer teams, enabling simple and sane dependency management, stable and predictable testing, and hassle-free developer onboarding and workflows.
            </p>
        )
    },
    {
        title: "Tier 1 Consulting Group",
        demoUrl: "https://www.t1cg.io/",
        description: (
            <p>
                As a consultant with Tier 1, I led a complete overhaul of two full stack TypeScript applications, achieving major performance gains, asset size reductions, and accessiblity improvments on the frontend. I also improved backend application interfaces to adhere to REST principles, and integrated ORM and ODM technology as clients to database systems.
            </p>
        )
    }
]