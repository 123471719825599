import React, { PropsWithChildren } from "react";

interface ExternalLinkProps extends PropsWithChildren<{href: string}> {};

export default function ExternalLink({href, children}: ExternalLinkProps) {
    return (
        <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
        >
            {children}
        </a>
    );
}
