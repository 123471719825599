import React from 'react';
import { Route, Routes } from "react-router-dom";
import Address from './address/Address';
import './App.css';
import { routes } from './common/routes';
import Home from './home/Home';
import Nav from './nav/Nav';
import Portfolio from './portfolio/Portfolio';
import Toolkit from './toolkit/Toolkit';

function App() {
  return (
    <div>
      <header>
        <h1>jeff bothe</h1>
        <Nav />
      </header>
        <Routes>
          <Route path={routes.home} element={<Home />} />
          <Route path={routes.portfolio} element={<Portfolio />} />
          <Route path={routes.toolkit} element={<Toolkit />} />
        </Routes>
      <footer>
        <Address/>
      </footer>
    </div>
  );
}

export default App;
