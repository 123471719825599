import React from "react";

export default function Home() {
    return (
        <section>
            <h2 className="visually-hidden">Home</h2>
            <p>
                I am a software engineer based in Baltimore, MD, USA.
            </p>
            <p>
                As a results-driven professional, my work focuses on the essentials of pattern-oriented software design and agile software development processes. I seek out challenges and opportunities where I can find them, and I strive to be a leader and driver of change in everything I do.
            </p>
            <p>
                I am passionate about technology and community, and I live to solve tough problems and make cool stuff. I devote most of my free time to making music, exploring the great outdoors, and gossiping about nature and the universe.
            </p>
        </section>
    )
}
