export const addressItems = [
    {
        href: "mailto: jeffrybothe@gmail.com",
        text: "jeffrymbothe@gmail.com"
    },
    {
        href: "https://github.com/jmbothe/",
        text: "github"
    },
    {
        href: "https://www.linkedin.com/in/jmbothe/",
        text: "linkedin"
    }
]
