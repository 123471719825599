import React from 'react';
import {render, hydrate} from 'react-dom';
import App from './App';
import { BrowserRouter } from "react-router-dom";

const element = (
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");

if (rootElement?.hasChildNodes()) {
  hydrate(element, rootElement);
} else {
  render(element, rootElement);
}
