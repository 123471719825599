import React from "react";
import { tools } from "./tools";

export default function Toolkit() {
    return (
        <section>
            <h2 className="visually-hidden">Toolkit</h2>
            <p>A non-exhaustive list of the technologies I have experience with.</p>
            <ul>
                {tools.map((toolName) => <li key={toolName}>{toolName}</li>)}
            </ul>
        </section>
    )
}
