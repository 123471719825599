import React from "react";
import AddressItem from "./AddressItem";
import { addressItems } from "./addressItems";

export default function Address() {
    return (
        <address>
            <ul>
                {
                    addressItems.map((item, index)=> (
                        <AddressItem
                            {...item}
                            key={item.text}
                            isLast={index === addressItems.length - 1}
                        />
                    ))
                }
            </ul>
        </address>
    )
}