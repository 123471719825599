import React from "react";
import { Link, useLocation } from "react-router-dom";
import InlineListItem from "../common/InlineListItem";

interface NavItemProps {
    name: string;
    path: string;
    isLast: boolean;
}

export default function NavItem({name, path, isLast}: NavItemProps) {
    const {pathname} = useLocation();

        return (
            <InlineListItem isLast={isLast}>
                {
                    path === pathname
                        ? <span className="active">{name}</span>
                        : <Link to={path}>{name}</Link>
                }
            </InlineListItem>
        )
}
